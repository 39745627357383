<template>
  <div>
    <div v-if="countLoading == true">
      now loading...
    </div>
    <div v-else-if="followersCountList.length == 0">
      no data
    </div>
    <div v-else>
        <img height="200" width="200" alt="profile_image" :src=latestInfo.profile_image_url>
        <div>
          <a :href="`https://twitter.com/` + latestInfo.username" target="_blank" rel="noopener noreferrer">@{{latestInfo.username}}</a> / {{latestInfo.name}}
        </div>
      <div class="description">{{latestInfo.description}}</div>

      <h2>followers count</h2>
      <canvas id="followers"></canvas>
      <h2>following count</h2>
      <canvas id="following"></canvas>
      <h2>listed count</h2>
      <canvas id="listed"></canvas>
      <h2>tweet count</h2>
      <canvas id="tweet"></canvas>
    </div>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';
import axios from 'axios'

export default {
  props: { 'user_id':String },
  data: function(){
    return {
      latestInfo:[],
      followersCountList:[],
      followingCountList:[],
      listedCountList:[],
      tweetCountList:[],
      dateList:[],
      target:'',
      countLoading: false,
    }
  },
  methods: {
    renderChart(id,data) {
      let ctx = document.getElementById(id);
      new Chart(ctx, {
        type: 'line',
        data:{
          labels: this.dateList,
          datasets: [{
            label: id,
            data:data,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },

    async getCountList(){
      this.countLoading = true;
      let url = 'https://czegvk2wl4.execute-api.us-east-1.amazonaws.com/dev/count/' + this.user_id
      let response = await axios.get(url)

      if(response.data.Items.length != 0){
        let latestIndex = response.data.Items.length -1
        this.latestInfo = response.data.Items[latestIndex]

        this.followersCountList = response.data.Items.map(item => item.public_metrics.followers_count);
        this.followingCountList = response.data.Items.map(item => item.public_metrics.following_count);
        this.listedCountList = response.data.Items.map(item => item.public_metrics.listed_count);
        this.tweetCountList = response.data.Items.map(item => item.public_metrics.tweet_count);

        //2020-12-31のように取り出す
        this.dateList = response.data.Items.map(item => item.date.substr(0,10));
      }

      this.countLoading = false;
    },

    async checkUserRegistered(){
     //404なのかどうか決める。つまりユーザー情報を取得してくる。
    },
  },
  async created() {
    await this.getCountList();

    this.renderChart('followers', this.followersCountList);
    this.renderChart('following', this.followingCountList);
    this.renderChart('listed', this.listedCountList);
    this.renderChart('tweet', this.tweetCountList);
  }
};
</script>

<style scoped>

canvas{
  height:calc(100vh - 200px)!important;
  width: auto!important;
  margin:0 auto;
}


.description{
  width: 65%;
  margin: 0 auto;
}
</style>