<template>
    <!--                                               -->
    <!--      いえーい！暇人みてるーーーーー？？              -->
    <!--                                               -->
    <!--              ＊    ＿＿＿ +                     -->
    <!--            ★  + ／)⌒ ⌒  ＼                     -->
    <!--            + _ヘ／／(●) ＜ ヽ                   -->
    <!--            ／ﾋﾉﾉ _二⊃(_人_)  |                  -->
    <!--                < +   ヽノ ノ                    -->
    <!--            ／￣＼) ＊     ＼                     -->
  <div id="nav">
    <router-link to="/" class="tab">HOME</router-link>
    <!-- <router-link to="/chart" class="tab">CHART</router-link> -->
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
