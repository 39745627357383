<template>
    <div>
      <h2>registered users list</h2>
      <div v-if="loading == true">
        now loading...
      </div>
      <table v-else border="1" align="center" style="border-collapse: collapse">
        <tr>
          <th v-if="checkbox">compare</th>
          <th>id</th>
          <th>img</th>
          <th>name</th>
          <th>username</th>
          <th>registered date</th>
        </tr>
        <tr v-for="user in $store.state.userList" :key=user.id>
          <td v-if="checkbox"><input type="checkbox" id="compare[]" :value="user.id" v-model="compareList"></td>
          <td><router-link :to="`/user/` + user.id">{{ user.id }}</router-link></td>
          <td class="user-icon-td" ><img class="user-icon" :src="user.profile_image_url"></td>
          <td>{{ user.name }}</td>
          <td>@{{ user.username }}</td>
          <td>{{ user.date }}</td>
        </tr>
      </table>
    </div>
</template>

<script>

export default {
  props:{
      checkbox:{
          type: Boolean,
          default:false,
      }
  },
  data: function(){
    return {
      loading: false,
      compareList:[],
    }
  },
  methods: {
    async getUserList(){
        //既にデータが存在する場合は実行しない
        if(this.$store.state.userList.length != 0) return

        this.loading = true
        await this.$store.dispatch('setUserList')
        this.loading = false
    },
  },
  created:function(){
    this.getUserList();
  },
  watch:{
    compareList: function(val){
      //例) /chart?id=1024452520343613440&id=1044576653718958081
      this.$router.push({ path:'chart', query:{ id: val } })
    }
  }
}

</script>

<style scoped>

.user-icon{
  vertical-align: bottom;
  width:30px;
  height:30px;
}

</style>
