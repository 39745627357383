<template>
  <div>
    <RegisteredUsers :checkbox=true />
  </div>
</template>

<script>

// import axios from 'axios'
import RegisteredUsers from '../components/RegisteredUsers'

export default {
  components: {RegisteredUsers},
  data: function(){
    return {
      searchLoading: false,
      userName: '',
      searchedUser: {},
      registedMessage: "",
      ip: "",
      compareList:[],
    }
  },
  methods: {
  },
  mounted:function(){
  },
  watch: {
    '$route': function (val) {
      //apiを実行する。一応vuexに情報を持たせる。
      console.log(val.query)
  }
  }
}

</script>


<style scoped>

</style>
