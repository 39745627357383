<template>
  <div>
    <div class="search_box">
        <p><font color="red">Twitter APIの無料提供の終了が発表されました。それに伴い2月9日以降、サイトが動作しなくなると思います。また、このサイトも近いうちに閉じます。ありがとうございました。( ^ω^ )</font></p>
        <h2>search twitter user</h2>
        @<input v-model="userName" placeholder="kawa_kawawaka">

        <div v-if="searchLoading == true">
          now searching...
        </div>
        <div v-else-if="Object.keys(searchedUser).length !== 0 ">
          <img class="searched-icon" :src=searchedUser.profile_image_url>
          <div>@{{ searchedUser.username }} / {{ searchedUser.name }}</div>

          <button @click="registerUser">register!!</button>
        </div>
        <div v-else>
          <span>user 「{{userName}}」 is not found</span>
        </div>

        <div v-if="registerLoading == true">
          now registering...
        </div>
        <div v-else>
          <span>{{registeredMessage}}</span>
        </div>
      <!-- </div> -->
    </div>
    <RegisteredUsers/>
  </div>
</template>

<script>

import axios from 'axios'
import RegisteredUsers from '../components/RegisteredUsers'

export default {
  components: {RegisteredUsers},
  data: function(){
    return {
      searchLoading: false,
      userName: '',
      searchedUser: {},
      registeredMessage: "",
      ip: "",
    }
  },
  methods: {
    async setIp(){
      let response = await axios.get('https://httpbin.org/get')
      this.ip = response.data.origin
    },
    async searchtUser(){
      this.searchLoading = true

      //空をセットする
      this.searchedUser = {}
      this.registeredMessage = '';

      let url = 'https://czegvk2wl4.execute-api.us-east-1.amazonaws.com/dev/user/' + this.userName;
      let response = await axios.get(url)
      this.searchedUser = response.data
      this.searchLoading = false
    },
    async registerUser(){
      this.registerLoading = true;

      let url = 'https://czegvk2wl4.execute-api.us-east-1.amazonaws.com/dev/user';
      let response = await axios.post(url,{
        id:this.searchedUser.id,
        username:this.searchedUser.username,
        name:this.searchedUser.name,
        profile_image_url:this.searchedUser.profile_image_url,
      })

      if(typeof response.data == 'string'){
          this.registeredMessage = response.data;
      }else{
        this.registeredMessage = "done!! now, user 「"  + this.searchedUser.username + "」 is registered";
        this.$store.dispatch('setUserList')
      }
      this.registerLoading = false;
    }
  },
  mounted:function(){
    this.setIp()
  },
  watch: {
    userName: function (newValue) {
      //インクリメンタルサーチ
      this.searchtUser(newValue);
    },
  }
}

</script>


<style scoped>
.searched-icon{
  margin-top: 5px;
}
.search_box{
  height: 200px;
}
</style>
