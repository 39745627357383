import { createStore } from 'vuex'
import axios from 'axios'

export const store = createStore({
    state(){
        return {
            userList:[]
        }
    },
    mutations: {
        setUserList (state, userList) {
            state.userList = userList
        }
    },
    actions: {
        async setUserList({commit}) {
            let url = 'https://czegvk2wl4.execute-api.us-east-1.amazonaws.com/dev/user/'
            let response = await axios.get(url)
            commit('setUserList', response.data.Items);
        }
    },
    modules: {}
});
